const Execise = [
    {
        id: 1,
        title: "Power Yoga Classes",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, quibusdam.",
        image: "https://th.bing.com/th/id/R.f36c11397060d9efc33b291a5fb2f1af?rik=rFLJCY9LdIC6Uw&pid=ImgRaw&r=0",
        Rate: 65
    },
    {
        id: 2,
        title: "Weight Lifting Classes ",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, quibusdam.",
        image: "https://th.bing.com/th/id/OIP.dbVQ1PsMkWmVqdfoIFJ8cwHaE8?dpr=1.3&pid=ImgDetMain",
        Rate: 95
    },
    {
        id: 3,
        title: "Weight Lifting Classes ",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, quibusdam.",
        image: "https://th.bing.com/th/id/R.40b15d0950c265fc2cbb36a02de96b14?rik=ajRWpgjKN3lDVA&pid=ImgRaw&r=0",
        Rate: 85
    },
    {
        id: 4,
        title: "cardio & strength Classes",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, quibusdam.",
        image: "https://th.bing.com/th/id/OIP.FAsOhbzf2tsfjx4Sik2F4QHaGa?rs=1&pid=ImgDetMain",
        Rate: 75
    },
    {
        id: 5,
        title: "Power Yoga Classes",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, quibusdam.",
        image: "https://th.bing.com/th/id/R.f36c11397060d9efc33b291a5fb2f1af?rik=rFLJCY9LdIC6Uw&pid=ImgRaw&r=0",
        Rate: 85
    },
    {
        id: 6,
        title: "Weight Lifting Classes ",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, quibusdam.",
        image: "https://th.bing.com/th/id/OIP.dbVQ1PsMkWmVqdfoIFJ8cwHaE8?dpr=1.3&pid=ImgDetMain",
        Rate: 100
    },
    {
        id: 7,
        title: "Weight Lifting Classes ",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, quibusdam.",
        image: "https://th.bing.com/th/id/R.40b15d0950c265fc2cbb36a02de96b14?rik=ajRWpgjKN3lDVA&pid=ImgRaw&r=0",
        Rate: 65
    },
    {
        id: 8,
        title: "Weight Lifting Classes ",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, quibusdam.",
        image: "https://th.bing.com/th/id/R.40b15d0950c265fc2cbb36a02de96b14?rik=ajRWpgjKN3lDVA&pid=ImgRaw&r=0",
        Rate: 65
    },
    {
        id: 9,
        title: "Weight Lifting Classes ",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, quibusdam.",
        image: "https://th.bing.com/th/id/R.40b15d0950c265fc2cbb36a02de96b14?rik=ajRWpgjKN3lDVA&pid=ImgRaw&r=0",
        Rate: 65
    },
]

export {Execise};